<template>
    <!-- <nav class="navbar  navbar-expand-lg ">
        <div class="container-fluid">
            <div class="container-fluid">
                <a class="navbar-brand" style="display: flex; flex-direction: row; cursor: pointer;">
                    <img :src="'/images/logo-purple.png'" alt="Logo" width="400" class="d-inline-block align-text-center"
                        @click="gohome()">
                </a>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav" style="width: 10%;">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-item text-decoration-none" to="/signin">
                            <span class="nav-link" style="color:#517aa8; font-size: 18px;">Regístrate</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav> -->
    <div
        style="background-color: #ff7a0b; width: 100%; height: 20vh; margin-bottom: 3rem; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="cuadro-busqueda col-md-4">
            <form class="input-group" @submit.prevent="getCompany()">
                <input type="search" class="form-control" v-model="searchInput"
                    placeholder="Buscar por título de vacante..." />
            </form>
        </div>
    </div>
    <div class="container-view-vac">
        <div class="tarjeta-vista">
            <div class="card mb-3 style-card" v-for="(item, i) in listVacantes" :key="i + 1" v-show="filter(item)">
                <div class="foto" style="flex: 1; display: flex; justify-content: center; align-items: center; ">
                    <img v-if="!item.vacante.private"
                        :src="item.userCompany.photo ? item.userCompany.photo.link : imageUrl"
                        class="rounded-circle photo-company"
                        style="aspect-ratio: 1/1; object-fit: cover; object-position: center;" alt="Logo Company">
                    <img v-else src="/images/profile.jpg" alt="Logo Company" class="rounded-circle"
                        style="width: 90%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                </div>
                <div class="card-body cuerpo-tarjeta">
                    <div class="info-company-text">
                        <span v-if="item.vacante.private">EMPRESA CONFIDENCIAL</span>
                    </div>
                    <div>
                        <span class="card-descripcion">"{{ item.vacante.titulo }}"</span>
                        <div class="ubicacion-icon">
                            <span style="margin-left: 2px; text-transform: capitalize;">
                                <small v-show="item.vacante.ubicacion">{{ item.vacante.ubicacion }}, </small>
                                <small v-show="item.vacante.estado"> {{ item.vacante.estado }} </small>
                            </span>
                        </div>
                        <!-- <span class="card-text-public fst-italic">
                            Publicada el {{ moment(item.vacante.createdAt).format('DD/MM/YYYY') }}
                        </span> -->
                    </div>
                    <div class="J-talento-btn-A mt-3">
                        <button class="btn-a" @click="viewregister2()">
                            <span class="txt-btn-a">
                                Postularme ahora
                            </span>
                        </button>
                        <button class="btn-a" v-on:click="linkVacante(item.vacante._id)">
                            <span class="txt-btn-a">
                                Ver Detalles
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment"
import { defineComponent } from "vue";
import { UserService } from "@/services";


export default defineComponent({
    data: () => ({
        moment: moment,
        search: "",
        searchInput: "",
        listVacantes: [],
        imageUrl: "/images/profile.jpg",
    }),
    components: {},
    created() {
        this.getCompany(this.search);
    },
    methods: {
        async getCompany() {
            const resp = await UserService.vacantesInicio({ search: this.search });
            const { data } = resp.data;
            this.listVacantes = data;
            console.log(this.listVacantes);
            this.filterVacantesTFecha(this.listVacantes);
            console.log(this.listVacantes);
        },
        filter(vacantes) {
            if (this.searchInput != "") {
                return vacantes.vacante.titulo.toLowerCase().match(this.searchInput.toLowerCase()) || vacantes.vacante.ubicacion.toLowerCase().match(this.searchInput.toLowerCase()) || vacantes.vacante.modalidad.toLowerCase().match(this.searchInput.toLowerCase()) || vacantes.nameCompany.toLowerCase().match(this.searchInput.toLowerCase());
            }
            else {
                return vacantes;
            }
        },
        viewregister2() {
            window.location.href = "/signin";
        },
        gohome() {
            window.location.href = "/";
        },
        linkVacante(id) {
            window.location.href = ("https://mundouniversitario.mx/vacantes/vista/" + id);
        },
        //TODO: Metodo Ordenar Vacantes fecha
        filterVacantesTFecha(vacantes) {
            vacantes.sort((a, b) => {
                if (a.vacante.createdAt == b.vacante.createdAt) {
                    return 0;
                }
                if (a.vacante.createdAt > b.vacante.createdAt) {
                    return -1;
                }
                return 1;
            });
            return vacantes;
        },
    },
});
</script>
<style land="scss">
@import "../styles/vacantesPre.scss";
</style>